import ApiMicroService from "./config-api";

export const getListOrganization = async (params: any) => {
  try {
    const result = await ApiMicroService.get("/qrnet-service/organization_v2", {
      params,
    });
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const getOrganizationDetail = async (id: any) => {
  try {
    const result = await ApiMicroService.get(
      `/qrnet-service/organization_v2/${id}`
    );
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const createOrganization = async (data: any) => {
  try {
    const result = await ApiMicroService.post(
      `/qrnet-service/organization_v2`,
      data
    );
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const editOrganizationInfo = async (id: any, data: any) => {
  try {
    const result = await ApiMicroService.put(
      `/qrnet-service/organization_v2/${id}`,
      data
    );
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const uploadLogoOrganization = async (data: any) => {
  try {
    const result = await ApiMicroService.post(
      "/qrnet-service/common/upload/image",
      data,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    );
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const listTeamOrganization = async (id: any, params: any) => {
  try {
    const result = await ApiMicroService.get(
      `/qrnet-service/organization_v2/team/${id}`,
      { params }
    );
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const getDetailTeam = async (id: any, teamId: any) => {
  try {
    const result = await ApiMicroService.get(
      `/qrnet-service/organization_v2/team/${id}/${teamId}`
    );
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const createTeam = async (id: any, data: any) => {
  try {
    const result = await ApiMicroService.post(
      `/qrnet-service/organization_v2/team/${id}`,
      data
    );
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const editTeam = async (id: any, teamId: any, data: any) => {
  try {
    const result = await ApiMicroService.put(
      `/qrnet-service/organization_v2/team/${id}/${teamId}`,
      data
    );
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const listProfileOrganization = async (id: any, params: any) => {
  try {
    const result = await ApiMicroService.get(
      `/qrnet-service/organization_v2/user/${id}`,
      { params }
    );
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const createProfileOrg = async (id: any, data: any) => {
  try {
    const result = await ApiMicroService.post(
      `/qrnet-service/organization_v2/user/${id}`,
      data
    );
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const editUserProfile = async (orgId: any, userId: any, data: any) => {
  try {
    const result = await ApiMicroService.put(
      `/qrnet-service/organization_v2/user/${orgId}/${userId}`,
      data
    );
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const getListReport = async (params: any) => {
  try {
    const result = await ApiMicroService.get(`/qrnet-service/report`, {
      params,
    });
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const getReportDetail = async (recordId: any) => {
  try {
    const result = await ApiMicroService.get(
      `/qrnet-service/report/${recordId}`
    );
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const listTemplateDefault = async () => {
  try {
    const result = await ApiMicroService.get(`/qrnet-service/card`);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const getListTemplate = async (id: any, params: any) => {
  try {
    const result = await ApiMicroService.get(
      `/qrnet-service/organization_v2/template/${id}`,
      { params }
    );
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const createTemplate = async (id: any, data: any) => {
  try {
    const result = await ApiMicroService.post(
      `/qrnet-service/organization_v2/template/${id}`,
      data
    );
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const editTemplate = async (orgId: any, templateId: any, data: any) => {
  try {
    const result = await ApiMicroService.put(
      `/qrnet-service/organization_v2/template/${orgId}/${templateId}`,
      data
    );
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const getTemplateDetail = async (orgId: any, templateId: any) => {
  try {
    const result = await ApiMicroService.get(
      `/qrnet-service/organization_v2/template/${orgId}/${templateId}`
    );
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const getListPosition = async (id: any, data: any) => {
  try {
    const result = await ApiMicroService.get(
      `/qrnet-service/organization/position/list?orgId=${id}&page=1&page_size=20&search=${data}`
    );
    return result.data;
  } catch (error) {
    throw error;
  }
};

// send request

export const sendInvitation = async (orgId: any, data: any) => {
  try {
    const result = await ApiMicroService.post(
      `/qrnet-service/organization/email/send/${orgId}`,
      data
    );
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const cancelInvitation = async (orgId: any, userId: any) => {
  try {
    const result = await ApiMicroService.put(
      `/qrnet-service/organization/email/cancel/${orgId}/${userId}`
    );
    return result.data;
  } catch (error) {
    throw error;
  }
};

// related companies

export const getListRelatedCompany = async (orgId: any, params: any) => {
  try {
    const result = await ApiMicroService.get(
      `/qrnet-service/organization_v2/related/${orgId}`,
      { params }
    );
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const editRelationship = async (orgId: any, childId: any, data: any) => {
  try {
    const result = await ApiMicroService.put(
      `/qrnet-service/organization_v2/related/${orgId}/${childId}`,
      data
    );
    return result.data;
  } catch (error) {
    throw error;
  }
};
export const deleteRelationship = async (
  orgId: any,
  childId: any,
  data: any
) => {
  try {
    const result = await ApiMicroService.put(
      `/qrnet-service/organization_v2/related/${orgId}/${childId}`,
      data
    );
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const getDetailUserProfile = async (orgId: any, userId: any) => {
  try {
    const result = await ApiMicroService.get(
      `/qrnet-service/organization_v2/user/${orgId}/${userId}`
    );
    return result.data;
  } catch (error) {
    throw error;
  }
};

// list organization admin

export const getListOrganizationAdmin = async (params: any) => {
  try {
    const result = await ApiMicroService.get(
      `/qrnet-service/organization/list_org_admin`,
      { params }
    );
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const getListImportOrganization = async (params: any) => {
  try {
    const result = await ApiMicroService.get(
      `/qrnet-service/organization_v2/import`,
      { params }
    );
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const importProfileToOrganization = async (orgId: any, data: any) => {
  try {
    const result = await ApiMicroService.put(
      `/qrnet-service/organization_v2/import/${orgId}`,
      data
    );
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const importExcelProfileToOrganization = async (
  orgId: any,
  data: any
) => {
  try {
    const result = await ApiMicroService.put(
      `/qrnet-service/organization_v2/import_excel/${orgId}`,
      data
    );
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const editVerificationEmail = async (orgId: any) => {
  try {
    const result = await ApiMicroService.get(
      `/qrnet-service/organization/verification/${orgId}`
    );
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const verificationEmail = async (orgId: any, data: any) => {
  try {
    const result = await ApiMicroService.put(
      `/qrnet-service/organization/verification/${orgId}`,
      data
    );
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const getProfileBasic = async (params: any) => {
  try {
    const result = await ApiMicroService.get("/qrnet-service/profile/basic", {
      params,
    });
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const getListRequest = async (params: any) => {
  try {
    const result = await ApiMicroService.get(
      "/qrnet-service/organization/request_verify",
      {
        params,
      }
    );
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const getDetailRequest = async (id: any) => {
  try {
    const result = await ApiMicroService.get(
      `/qrnet-service/organization/request_verify/${id}`
    );
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const verificationOrganization = async (id: any) => {
  try {
    const result = await ApiMicroService.post(
      `/qrnet-service/organization_v2/confirm_verified/${id}`
    );
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const addProfileToOrganization = async (orgId: number, data: any) => {
  try {
    const result = await ApiMicroService.post(
      `/qrnet-service/organization_v2/user/create_member/${orgId}`, data
    );
    return result.data;
  } catch (error) {
    throw error;
  }
}

export const getListIndustry = async (params: any) => {
  try {
    const result = await ApiMicroService.get(`/qrnet-service/industry`, {
      params,
    });
    return result.data;
  } catch (error) {
    throw error;
  }
}