import { UserOutlined, TeamOutlined, AuditOutlined, HomeOutlined, DashboardOutlined, IdcardOutlined, SettingOutlined } from '@ant-design/icons/lib'
import { IconCompany } from '../../assets/icon/IconCompany'

export const MENU_ADMIN_SIDEBAR: any[] = [
     {
          key: "users_customer",
          path: "/users/customer",
          name: "Users",
          icon: <UserOutlined />,
     },
     {
          key: "organization-account",
          path: "/organization-account",
          name: "Organization Account",
          icon: <TeamOutlined />,
          // child: [
          //      { key: 'join-request', icon: <UserOutlined />, link: '/join-org-request', name: 'Join Request' },
          // ]
     },
     {
          key: "join-request",
          path: "/join-request",
          name: "Join Request",
          icon: <IdcardOutlined />
     },
     {
          key: "report",
          path: "/report",
          name: "Report",
          icon: <AuditOutlined />
     },
     {
          key: "cards",
          path: "/cards",
          name: "NFC Cards",
          icon: <IdcardOutlined />
     },
     {
          key: "Administration",
          path: "/administration",
          name: "Administration",
          icon: <SettingOutlined />,
          child: [
               { key: 'users-admin', icon: <UserOutlined />, link: '/users/admin', name: 'User role' },
               { key: 'users_verifications-request', icon: <SettingOutlined />, link: '/users/verifications-request', name: 'Verifications' }
          ]
     },
     {
          key: "feed-moderation",
          path: "/feed-moderation",
          name: "Feed Moderation",
          icon: <IdcardOutlined />
     },
     {
          key: "promotions",
          path: "/banner",
          name: "Promotions & Programs Management",
          icon: <IdcardOutlined />,
          child: [
               { key: 'banner', icon: <UserOutlined />, link: '/banner', name: 'Banner/ Pop-up' },
               { key: 'promotion', icon: <SettingOutlined />, link: '/program', name: 'Program' }
          ]
     },

]

export const MENU_ORG_SIDEBAR: any[] = [
     {
          key: "overview",
          path: "/overview",
          name: "Overview",
          icon: <HomeOutlined />
     },
     {
          key: "profile",
          path: "/profile",
          name: "Profile",
          icon: <UserOutlined />
     },
     {
          key: "teams",
          path: "/teams",
          name: "Teams",
          icon: <TeamOutlined />
     },
     {
          key: "templates",
          path: "/templates",
          name: "Templates",
          icon: <IdcardOutlined />
     },
     {
          key: "Administration",
          path: "/administration",
          name: "Administration",
          icon: <SettingOutlined />,
          child: [
               { key: 'verification', icon: <UserOutlined />, link: '/verification', name: 'Verification' },
          ]
     }
]
