import { lazy } from "react";
import BasicLayout from "../components/layouts/BasicLayout";
import NothingLayout from "../components/layouts/NothingLayout";
import { ROUTES_TYPE } from "../interface/route";

// for admin QRnet
export const routes: ROUTES_TYPE[] = [
    {
        path: '/',
        name: "Users",
        component: lazy(async () => {
            await new Promise(resolve => setTimeout(resolve, 1700));
            return import('../components/main/users/users-customer/ListUserCustomer');
        }),
        layout: BasicLayout
    },
    {
        path: '/login',
        name: "Home",
        component: lazy(async () => {
            await new Promise(resolve => setTimeout(resolve, 1700));
            return import('../components/screens/Login/LoginAdmin');
        }),
        layout: NothingLayout
    },
    {
        path: '/users',
        name: 'ListUserAdmin',
        component: lazy(() => import('../components/main/users/users-admin/ListUserAdmin')),
        layout: BasicLayout
    },
    {
        path: '/users/customer',
        name: "ListUserCustomer",
        component: lazy(() => import('../components/main/users/users-customer/ListUserCustomer')),
        layout: BasicLayout
    },
    {
        path: '/users/admin',
        name: "ListUserAdmin",
        component: lazy(() => import('../components/main/users/users-admin/ListUserAdmin')),
        layout: BasicLayout
    },
    {
        path: '/users/verifications-request',
        name: "VerificationRequest",
        component: lazy(() => import('../components/main/users/users-admin/VerificationRequest/VerificationRequest')),
        layout: BasicLayout
    },
    {
        path: '/users/verification/:id',
        name: "RequestDetail",
        component: lazy(() => import('../components/main/users/users-admin/VerificationRequest/RequestDetail')),
        layout: BasicLayout
    },
    {
        path: '/users/create-role',
        name: "CreateUserRole",
        component: lazy(() => import('../components/main/users/users-admin/CreateUserRole')),
        layout: BasicLayout
    },
    {
        path: '/users/edit-role/:userId',
        name: "EditUserRole",
        component: lazy(() => import('../components/main/users/users-admin/CreateUserRole')),
        layout: BasicLayout
    },
    {
        path: '/admin/user-detail/:userId',
        name: 'UserDetail',
        component: lazy(() => import('../components/main/users/user-detail/User')),
        layout: BasicLayout
    },
    {
        path: '/customer/user-detail/:userId',
        name: 'UserDetailCustomer',
        component: lazy(() => import('../components/main/users/user-detail/User')),
        layout: BasicLayout
    },
    {
        path: '/organization-account',
        name: 'Organization Account',
        component: lazy(() => import('../components/main/organization-account/ListOrganizationAcount')),
        layout: BasicLayout
    },
    {
        path: '/account-detail/:accountId',
        name: 'Account',
        component: lazy(() => import('../components/main/organization-account/organization-detail/Organization')),
        layout: BasicLayout
    },
    {
        path: '/create-organization',
        name: 'Create Organization',
        component: lazy(() => import('../components/main/organization-account/CreateOrganization')),
        layout: BasicLayout
    },
    {
        path: '/add-related-organization/:accountId',
        name: 'Add Related Organization',
        component: lazy(() => import('../components/main/organization-account/CreateOrganization')),
        layout: BasicLayout
    },
    {
        path: '/edit-related-organization/:accountId',
        name: 'Edit Related Organization',
        component: lazy(() => import('../components/main/organization-account/CreateOrganization')),
        layout: BasicLayout
    },
    {
        path: '/edit-organization/:accountId',
        name: 'Edit Oraganization',
        component: lazy(() => import('../components/main/organization-account/CreateOrganization')),
        layout: BasicLayout
    },
    {
        path: '/create-profile/:orgId',
        name: 'Create Profile',
        component: lazy(() => import('../components/main/organization-account/organization-detail/organization-profile/CreateProfile')),
        layout: BasicLayout
    },
    {
        path: '/add-profile/:orgId',
        name: 'Add Profile',
        component: lazy(() => import('../components/main/organization-account/organization-detail/organization-profile/AddProfile')),
        layout: BasicLayout
    },
    {
        path: '/import-profile/:orgId',
        name: 'Import Profile',
        component: lazy(() => import('../components/main/organization-account/organization-detail/organization-profile/ImportProfile/ImportProfile')),
        layout: BasicLayout
    },
    {
        path: '/move-profile/:orgId',
        name: 'Move Profile',
        component: lazy(() => import('../components/main/organization-account/organization-detail/organization-profile/MoveProfile')),
        layout: BasicLayout
    },
    {
        path: '/edit-profile/:orgId/:profileId',
        name: 'Edit Profile',
        component: lazy(() => import('../components/main/organization-account/organization-detail/organization-profile/CreateProfile')),
        layout: BasicLayout
    },
    {
        path: '/create-teams/:orgId',
        name: 'Create Teams',
        component: lazy(() => import('../components/main/organization-account/organization-detail/organization-team/CreateTeams')),
        layout: BasicLayout
    },
    {
        path: '/edit-teams/:teamId/:orgId/',
        name: 'Edit Teams',
        component: lazy(() => import('../components/main/organization-account/organization-detail/organization-team/CreateTeams')),
        layout: BasicLayout
    },
    {
        path: '/create-template/:orgId',
        name: 'Create Template',
        component: lazy(() => import('../components/main/organization-account/organization-detail/organization-template/CreateTemplate')),
        layout: BasicLayout
    },
    {
        path: '/edit-template/:orgId/:templateId',
        name: 'Edit Template',
        component: lazy(() => import('../components/main/organization-account/organization-detail/organization-template/CreateTemplate')),
        layout: BasicLayout
    },
    // report
    {
        path: '/report',
        name: 'Report',
        component: lazy(() => import('../components/main/report/Report')),
        layout: BasicLayout
    },
    {
        path: '/report/:reportId',
        name: 'Report detail',
        component: lazy(() => import('../components/main/report/ReportDetail')),
        layout: BasicLayout
    },
    // NFC Cards
    {
        path: '/cards',
        name: 'NFC Cards',
        component: lazy(() => import('../components/main/cards/ListCardNFC')),
        layout: BasicLayout
    },
    {
        path: '/cards/:code',
        name: 'Cards',
        component: lazy(() => import('../components/main/cards/CardDetail')),
        layout: BasicLayout
    },
    {
        path: '/join-request',
        name: 'Join Organization Request',
        component: lazy(() => import('../components/main/join-org-request/JoinRequest')),
        layout: BasicLayout
    },
    {
        path: '/join-request/:orgID/:userID',
        name: 'Report detail',
        component: lazy(() => import('../components/main/join-org-request/JoinRequestDetail')),
        layout: BasicLayout
    },
    {
        path: '/feed-moderation',
        name: 'Feed Moderation',
        component: lazy(() => import('../components/main/feed-moderation/FeedModeration')),
        layout: BasicLayout
    },
    {
        path: '/banner',
        name: 'Banner',
        component: lazy(() => import('../components/main/banner/Banner')),
        layout: BasicLayout
    },
    {
        path: '/banner/create',
        name: 'Create Banner',
        component: lazy(() => import('../components/main/banner/CreateBanner')),
        layout: BasicLayout
    },
    {
        path: '/banner/edit/:id',
        name: 'Edit Banner',
        component: lazy(() => import('../components/main/banner/CreateBanner')),
        layout: BasicLayout
    },
    {
        path: '/program',
        name: 'Program',
        component: lazy(() => import('../components/main/program/Program')),
        layout: BasicLayout
    },
    {
        path: '/program/create',
        name: 'Create Program',
        component: lazy(() => import('../components/main/program/CreateProgram')),
        layout: BasicLayout
    },
    {
        path: '/program/edit/:id',
        name: 'Edit Program',
        component: lazy(() => import('../components/main/program/CreateProgram')),
        layout: BasicLayout
    },
]

// for admin orgaznization

export const routesForOrg: ROUTES_TYPE[] = [
    {
        path: '/',
        name: "Overview",
        component: lazy(async () => {
            await new Promise(resolve => setTimeout(resolve, 1700));
            return import('../components/main/organization-account/organization-detail/organization-info/OrganizationInfo');
        }),
        // component: lazy(() => import('../components/main/organization-account/organization-detail/organization-info/OrganizationInfo')),
        layout: BasicLayout
    },
    {
        path: '/overview',
        name: "Overview",
        component: lazy(() => import('../components/main/organization-account/organization-detail/organization-info/OrganizationInfo')),
        layout: BasicLayout
    },
    {
        path: '/overview/?id=:id',
        name: "Overview",
        component: lazy(() => import('../components/main/organization-account/organization-detail/organization-info/OrganizationInfo')),
        layout: BasicLayout
    },
    {
        path: '/edit-organization/:accountId',
        name: 'Edit Oraganization',
        component: lazy(() => import('../components/main/organization-account/CreateOrganization')),
        layout: BasicLayout
    },
    {
        path: '/profile',
        name: 'Profile',
        component: lazy(() => import('../components/main/organization-account/organization-detail/organization-profile/OrganizationProfile')),
        layout: BasicLayout
    },
    {
        path: '/create-profile/:orgId',
        name: 'Create Profile',
        component: lazy(() => import('../components/main/organization-account/organization-detail/organization-profile/CreateProfile')),
        layout: BasicLayout
    },
    {
        path: '/edit-profile/:orgId/:profileId',
        name: 'Edit Profile',
        component: lazy(() => import('../components/main/organization-account/organization-detail/organization-profile/CreateProfile')),
        layout: BasicLayout
    },
    {
        path: '/import-profile/:orgId',
        name: 'Import Profile',
        component: lazy(() => import('../components/main/organization-account/organization-detail/organization-profile/ImportProfile/ImportProfile')),
        layout: BasicLayout
    },
    {
        path: '/teams',
        name: 'Team',
        component: lazy(() => import('../components/main/organization-account/organization-detail/organization-team/OrganizationTeam')),
        layout: BasicLayout
    },
    {
        path: '/create-teams/:orgId',
        name: 'Create Teams',
        component: lazy(() => import('../components/main/organization-account/organization-detail/organization-team/CreateTeams')),
        layout: BasicLayout
    },
    {
        path: '/edit-teams/:teamId/:orgId/',
        name: 'Edit Teams',
        component: lazy(() => import('../components/main/organization-account/organization-detail/organization-team/CreateTeams')),
        layout: BasicLayout
    },
    {
        path: '/templates',
        name: 'Templates',
        component: lazy(() => import('../components/main/organization-account/organization-detail/organization-template/OrganizationTemplate')),
        layout: BasicLayout
    },
    {
        path: '/create-template/:orgId',
        name: 'Create Template',
        component: lazy(() => import('../components/main/organization-account/organization-detail/organization-template/CreateTemplate')),
        layout: BasicLayout
    },
    {
        path: '/edit-template/:orgId/:templateId',
        name: 'Edit Template',
        component: lazy(() => import('../components/main/organization-account/organization-detail/organization-template/CreateTemplate')),
        layout: BasicLayout
    },
    {
        path: '/add-related-organization/:accountId',
        name: 'Add Related Organization',
        component: lazy(() => import('../components/main/organization-account/CreateOrganization')),
        layout: BasicLayout
    },
    {
        path: '/verification',
        name: "Verification",
        component: lazy(() => import('../components/main/organization-account/organization-detail/verification-account/VerificationAccount')),
        layout: BasicLayout
    },
]
